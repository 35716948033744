import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import {
  EmptyList,
  Layout,
  Loading,
  OrderForm,
  TgMain,
} from 'components';
import { OrderApi, UserApi } from 'api';

export default function OrderPage() {
  const { WebApp } = window.Telegram;

  const navigate = useNavigate();
  const { state } = useLocation();

  const [orderFields, setOrderFields] = useState(state.orderFields || {});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadData = async () => {
    setIsLoading(true);

    try {
      const userData = await UserApi.getCurrent();

      setOrderFields({
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phone: userData.phone,
        deliveryAddress: userData.address,
      });
    } catch (e) {
      setError(e);
    }

    setIsLoading(false);
  };

  const onFormChange = (key, value) => {
    setOrderFields({ ...orderFields, [key]: value });
  };

  const onBack = () => {
    navigate(-1, { state });
  };

  const onSubmit = async () => {
    setIsLoading(true);

    const products = Object.entries(state.productFields);
    const items = products.map(([product, quantity]) => ({ product, quantity }));
    const data = {
      ...orderFields,
      items,
      deliveryDate: state.timetable.sheduledAt,
      menu: state.timetable.menu.id,
    };

    if (data.deliveryPeriod === '') {
      delete data.deliveryPeriod;
    }

    try {
      const response = await OrderApi.create(data);
      navigate('/invoice', {
        state: {
          orderId: response.id,
          timetable: state.timetable,
        },
      });
    } catch (e) {
      WebApp.showAlert('Проверьте правильность заполнения формы.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading || !state.timetable) {
    return <Loading text="Загрузка..." />;
  }

  if (error) {
    return <EmptyList text="Не удалось загрузить страницу." />;
  }

  return (
    <Layout>
      <TgMain text="Продолжить" onClick={onSubmit} />
      <div className="header">
        <button type="button" className="header__back" onClick={onBack}>Назад</button>
      </div>
      <OrderForm
        periods={state.timetable.menu.deliveryPeriods || []}
        pickupText={state.timetable.districtPickupDescription}
        data={orderFields}
        onChange={onFormChange}
      />
    </Layout>
  );
}
